import { singleton, inject } from "tsyringe";

// domain
import type { RequestGetStudentChallenges } from "@/domain/academy/student-challenge/repositories/repositories";
import type { GetStudentChallengesResponseDto } from "@/domain/academy/student-challenge/dtos";
import { StudentChallengeRepository } from "@/domain/academy/student-challenge/repositories/repositories";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class StudentChallengesRepositoryImpl implements StudentChallengeRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    getStudentChallenges = async (request: RequestGetStudentChallenges) => {
        let url = `/v1/academy/${request.academyId}/student-challenges?`;

        url += `challengeTemplateId=${request.challengeTemplateId}&`;
        url += `keyword=${request.keyword}&`;
        url += `size=${request.size}&`;

        if (request.nextToken) {
            url += `nextToken=${request.nextToken}`;
        }

        const response = await this.server.get<GetStudentChallengesResponseDto>(url);

        return response;
    };
}
