import { singleton, inject } from "tsyringe";

// domain
import {
    SubmittedProblemRepository,
    RequestGetSubmittedProblemsPayload,
} from "@/domain/user/student/submitted-problems/repositories/repositories";
import { GetStudentSubmittedProblemsResponseDto } from "@/domain/user/student/submitted-problems/dtos";

// infrastructure
import HttpAdaptor from "@/infrastructure/libs/http";

@singleton()
export default class SubmittedProblemsRepositoryImpl implements SubmittedProblemRepository {
    constructor(@inject(HttpAdaptor) private readonly server: HttpAdaptor) {}

    /** @description 챌린지 템플릿 목록 조회 */
    getStudentSubmittedProblems = async (request: RequestGetSubmittedProblemsPayload) => {
        let url = `/v1/students/${request.studentId}/submitted-problems?`;

        url += `size=${request.size}&`;

        if (request.nextToken) {
            url += `nextToken=${request.nextToken}`;
        }

        const response = await this.server.get<GetStudentSubmittedProblemsResponseDto>(url);

        return response;
    };
}
