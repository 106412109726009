import { defineStore } from "pinia";

import type {
    IChallengeSearchStudentActions,
    IChallengeSearchStudentGetters,
    IChallengeSearchStudentState,
} from "./interface";

const useChallengeSearchStudentStore = defineStore<
    "challengeSearchStudentStore",
    IChallengeSearchStudentState,
    IChallengeSearchStudentGetters,
    IChallengeSearchStudentActions
>("challengeSearchStudentStore", {
    state: (): IChallengeSearchStudentState => ({
        nextTokenArray: [null],
        searchStudentId: null,
        searchStudentName: "",
    }),
    getters: {
        paginationBaseIndex: (state) => {
            return (index: string) => {
                return state.nextTokenArray[index];
            };
        },
    },
    actions: {
        setNextPage(nextToken: string) {
            // 이미 있는 nextToken 이라면 추가하지 않습니다.
            if (this.nextTokenArray.includes(nextToken)) return;

            this.nextTokenArray.push(nextToken);
        },
        changeSearchKeyword() {
            this.nextTokenArray = [null];
            this.searchStudentId = null;
            this.searchStudentName = "";
        },
        saveSearchStudentResult(studentId: string, searchStudentName: string) {
            console.log(studentId, searchStudentName);
            this.searchStudentId = studentId;
            this.searchStudentName = searchStudentName;
        },
        resetState() {
            this.nextTokenArray = [];
            this.searchStudentId = null;
            this.searchStudentName = "";
        },
    },
});

export default useChallengeSearchStudentStore;
